<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <dark-Toggler class="d-block" />
      <!-- radio button -->
      <!--          @change="() => setDeliveryOrDineIn({deliveryOrDineIn: selectedRadio})"-->
      <div v-if="user.role === 'client'" class="ml-1">
        <b-form-radio-group
          v-model="selectedRadio"
          button-variant="outline-primary"
          :options="optionsRadio"
          buttons
          size="sm"
        />
      </div>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status"
              >{{ user.role !== 'client' ? user.role : '' }} {{ user.email }}</span
            >
          </div>
          <b-avatar
            size="40"
            variant="light-secondary"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormRadioGroup,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapMutations, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BLink,
    BNavbarNav,
    BFormRadioGroup,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      console,
      optionsRadio: [
        { text: 'Delivery', value: 'delivery' },
        { text: 'Dine In', value: 'dinein' },
      ],
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.userData,
      deliveryOrDineIn: state => state.deliveryOrDineIn.deliveryOrDineIn,
    }),
    selectedRadio: {
      get() {
        return this.deliveryOrDineIn
      },
      set(selectedRadio) {
        this.setDeliveryOrDineIn({ deliveryOrDineIn: selectedRadio })
      },
    },
  },
  methods: {
    ...mapMutations({
      setDeliveryOrDineIn: 'deliveryOrDineIn/SET',
    }),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
