export default [
  {
    title: 'Integrations',
    icon: 'GridIcon',
    children: [
      {
        title: 'Orders',
        route: 'home',
        icon: 'BookOpenIcon',
        resource: 'ACL',
        action: 'read',
      },
      {
        title: 'History',
        route: 'history',
        icon: 'ClockIcon',
        resource: 'ACL',
        action: 'read',
      },
      {
        title: 'Analytics',
        route: 'analytics',
        icon: 'BarChart2Icon',
        resource: 'ACL',
        action: 'read',
      },
    ],
  },
  {
    title: 'POS',
    icon: 'MonitorIcon',
    children: [
      {
        title: 'Staff Users',
        route: 'staff-users',
        icon: 'UsersIcon',
        resource: 'staff',
        action: 'write',
      },
      {
        title: 'Analytics',
        route: 'pos-analytics',
        icon: 'BarChart2Icon',
        resource: 'staff',
        action: 'write',
      },
    ],
  },
  {
    title: 'Menu',
    route: 'menu',
    icon: 'FileTextIcon',
    resource: 'ACL',
    action: 'read',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
    resource: 'admin',
    action: 'write',
  },
  {
    title: 'Performance',
    route: 'admin-analytics',
    icon: 'BarChart2Icon',
    resource: 'admin',
    action: 'write',
  },
]
